import axios from "axios"
import TRTC from "trtc-js-sdk";
import Bus from "./bus"
import Vue from "vue";

// import * as LibGenerateTestUserSig from './lib-generate-test-usersig.min.js'
class o2vrClient {
    constructor() {
        //凭证
        // 1400610125 dongsheng 1400610125
        this.config = {
            mode: "rtc",
            sdkAppId: '',
            userId: '',
            usersig: ''
        }
        //客户端
        this.client = "" //连接通信
        this.localStream = ""//本地流
        this.shareStream = ""//屏幕分享流
        this.list = [] //远方流列表
        this.share = false //分享屏幕
        this.cameraFlag = false  //是否拥有摄像头
        this.clientFlag = false //是否进入会议室

        this.shareFlag = false //是否开启摄像头
        this.share_id = ""

        this.type = 0  // 0普通 1大会议室 。。。。。

        this.typeFlag = false //大会议室权限
        this.roomId = ""
        this.audioFlag = false
        this.videoFlag = false
        this.pcflag = true
    }


    //登录client
    async login(userid) {


        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                this.pcflag = false;
                break;
            }
        }





        await this.get(userid).then(res => {
            this.config.userId = userid
            this.config.userSig = res.sig
            console.log(this.config.userSig)
            this.config.sdkAppId = res.appid
        })

        //建立客户端
        this.client = await TRTC.createClient(this.config);

        if (this.pcflag) {
            //屏幕分享
            this.share_id = `share-${userid}`
            await this.get(this.share_id).then(res => {
                //分享客户端
                this.shareClient = TRTC.createClient({
                    mode: "rtc",
                    sdkAppId: this.config.sdkAppId,
                    userId: this.share_id,
                    userSig: res.sig
                });
            })
            //屏幕分享单独订阅
            this.shareClient.on('stream-added', event => {
                const remoteStream = event.stream;
                this.shareClient.unsubscribe(remoteStream);
            });

            this.shareStream = TRTC.createStream({ screenAudio: true, screen: true });
            this.shareStream.setScreenProfile({ frameRate: 25, bitrate: 1600 /* kbps */ })
            this.shareStream.setScreenProfile('720p');
            this.shareStream.on('screen-sharing-stopped', event => {
                console.log(event)
                this.share = false
                Bus.$emit('shareClose', '')
                Bus.$emit("stream", 'screenleave')
                // 屏幕分享客户端停止推流
                this.shareClient.unpublish(this.shareStream);
                // 关闭屏幕分享流
                this.shareStream.close();
                // 屏幕分享客户端退房
            });
        }

        //监听屏幕分享流

        // let remoteStreamList = [];



        //订阅远方流
        await this.client.on("stream-added", (event) => {
            const remoteStream = event.stream;
            console.log("远端流增加: " + remoteStream.getId());
            this.client.subscribe(remoteStream);
            var i = String(this.roomId).charAt(0);
            if (this.roomId == 3001 || i == "4") {
                var mp3 = require('../../dongsheng/assets/audio/dingdong.mp3')
                var mp3 = new Audio(mp3)
                mp3.play()
            }
        });



        //远方流移除


        await this.client.on('stream-removed', (event) => {

            const remoteStream = event.stream;
            var name = remoteStream.getUserId()
            var tag = name.split('-')[0]
            if (tag == 'share') {
                Bus.$emit('shareClose', '')
                Bus.$emit("stream", 'screenleave')
                this.share = false
                return
            } else {

                Bus.$emit("removelist", remoteStream)
            }



        })

        // 播放远端流
        await this.client.on("stream-subscribed", (event) => {
            const remoteStream = event.stream;
            console.log(remoteStream.getUserId)
            var name = remoteStream.getUserId()
            var tag = name.split('-')[0]
            var sharename = name.split('-')[1]

            if (tag == 'share') {
                this.share = true;
                Bus.$emit('shareScreen', { flag: true, sharename })

                setTimeout(() => {
                    remoteStream.play('shareScreen');
                    Bus.$emit("stream", 'shareScreen')

                }, 1);

                //屏幕流单独分出来
            } else {

                Bus.$emit("addlist", remoteStream)



            }
        });

        //获取拉取流状态
        let getAudioLevelTimer = -1;
        var that = this
        this.startGetAudioLevel = function () {
            getAudioLevelTimer = setAnimationFrame(() => {
                var list = that.client.getRemoteMutedState()
                this.list = list
                Bus.$emit('listChange', list)
            });
        }

        // 停止获取
        this.stopGetAudioLevel = () => {
            if (getAudioLevelTimer !== -1) {
                clearAnimationFrame(getAudioLevelTimer);
                getAudioLevelTimer = -1;
            }
        }

        // 设置AnimationFrame
        function setAnimationFrame(render) {
            // 计时器
            let timer = {};
            function animeLoop() {
                render();
                timer.id = requestAnimationFrame(animeLoop);
            }
            animeLoop();
            return timer;
        }
        // 清除AnimationFrame
        function clearAnimationFrame(timer) {
            cancelAnimationFrame(timer.id);
        }



        this.join(document.o2.room_id)


    }
    async join(e) {

        this.audioFlag = false
        this.videoFlag = false
        var c = Vue.prototype.$chat
        //获取设备
        this.roomId = e
        console.log(e)
        if (Number(c.role.auth) == 5 && Number(c.user_group) == e) {
            this.type = 0
        }
        // else if (e == 1001) {
        //     this.type = 1
        // } else {
        //     this.type = 0
        // }
        // var i = String(e).charAt(0)
        // if (Number(c.role.auth) < 3 && (e == 2001 || e == 2002 || e == 2004 || e == 2005 || e == 2003 || i == '4')) {
        //     Vue.prototype.$message.error('友友你好，企业展厅仅限企业员工进入，请前往i友未来社区APP进行企业认证。')
        //     return
        // }
        // if (Number(c.role.auth) < 3 && e == 2034) {
        //     Vue.prototype.$message.error('友友你好，管理驾驶舱面向生态合作伙伴开放，请到入口处i站找小i沟通吧')
        //     return
        // }

        // var that = this
        const cameraList = await TRTC.getCameras();//获取摄像头
        const micList = await TRTC.getMicrophones();//获取麦

        const cameraFlag = cameraList.length > 0 //是否用有麦克风和摄像头

        const micFlag = micList.length > 0



        this.cameraFlag = cameraFlag
        this.micFlag = micFlag
        console.log(cameraFlag, 12222222222222)
        //没有麦克风提示无法登陆
        if (!micFlag) {
            Bus.$emit('micError', '')

        }

        if (!cameraFlag) {
            Bus.$emit('cameraError', '')
        }

        //进入房间
        await this.client
            .join({ roomId: e })
            .catch((error) => {
                console.error("进房失败 " + error);
            })
            .then(() => {
                console.log("进房成功");
                Bus.$emit('login', '')
                this.list = []
                this.startGetAudioLevel()
            });

        if (this.pcflag) {
            this.shareClient.join({ roomId: e }).then(() => {
                console.log('shareClient join success');
                // 创建屏幕分享流
            });
        }



        this.localStream = TRTC.createStream({
            userId: this.config.userId,
            audio: micFlag,
            video: cameraFlag,
        });

        this.publish()
    }
    //发布本地流
    async publish() {


        //初始化本地音视频流。
        await this.localStream
            .initialize()
            .catch((error) => {
                console.log("初始化本地流失败 " + error)
                return
            })
            .then(() => {
                console.log("初始化本地流成功",);
                this.localStream.play('local_stream');
            });

        this.localStream.muteAudio()

        const audioTrack = this.localStream.getAudioTrack();
        if (audioTrack) {
            audioTrack.stop();
        }
        const videoTrack = this.localStream.getVideoTrack();
        if (videoTrack) {
            await this.localStream.removeTrack(videoTrack)
            // 停止采集，关闭摄像头
            videoTrack.stop();
        }


    }

    //取消发布

    unpublish() {
        client.unpublish(localStream).then(() => {
            // 取消发布本地流成功
        });
    }
    async muteAudio(a) {

        var that = document.client

        if (!that.videoFlag && !that.audioFlag) {
            Bus.$emit('localFlag', true)
            that.client.publish(that.localStream)
        }


        that.audioFlag = a

        if (that.audioFlag) {
            const stream = TRTC.createStream({ audio: true, video: false });
            await stream.initialize();
            that.localStream.unmuteAudio();
            setTimeout(() => {
                that.localStream.replaceTrack(stream.getAudioTrack());
            }, 500);

        } else {
            that.localStream.muteAudio();
            const audioTrack = that.localStream.getAudioTrack();
            if (audioTrack) {
                audioTrack.stop();
            }
        }
        if (!that.videoFlag && !that.audioFlag) {
            that.client.unpublish(that.localStream)

            if (!that.shareFlag) {
                Bus.$emit('localFlag', false)
            }
        }
    }


    async muteVideo(a) {

        var that = document.client
        if (!that.videoFlag && !that.audioFlag) {
            that.client.publish(that.localStream)
            Bus.$emit('localFlag', true)
        }


        that.videoFlag = a

        if (that.videoFlag) {
            const videoStream = TRTC.createStream({ audio: false, video: true });
            await videoStream.initialize();
            await that.localStream.addTrack(videoStream.getVideoTrack());
        } else {
            const videoTrack = that.localStream.getVideoTrack();
            if (videoTrack) {
                await that.localStream.removeTrack(videoTrack)
                // 停止采集，关闭摄像头
                videoTrack.stop();
            }
        }

        if (!that.videoFlag && !that.audioFlag) {
            that.client.unpublish(that.localStream)
            if (!that.shareFlag) {
                Bus.$emit('localFlag', false)
            }
        }
    }

    async shareScreen(e) {
        var that = document.client

        that.shareFlag = e
        if (!this.pcflag) {
            return
        }
        if (!e) {
            that.share = false
            // 屏幕分享客户端停止推流w
            that.shareClient.unpublish(that.shareStream);
            // 关闭屏幕分享流
            that.shareStream.close();
            Bus.$emit('shareClose', '')
            Bus.$emit("stream", 'screenleave')
            if (!that.videoFlag && !that.audioFlag) {
                Bus.$emit('localFlag', false)
            }
            return
        }

        if (!that.videoFlag && !that.audioFlag) {
            Bus.$emit('localFlag', true)
        }
        that.shareStream.initialize().then(() => {
            // screencast stream init success
            that.shareClient.publish(that.shareStream).then(() => {
                console.log('screen casting');
            });
        });

    }

    async loginOut() {
        this.client
            .leave()
            .then(() => {
                // 退房成功，可再次调用client.join重新进房开启新的通话。
                console.log("退房成功")
                this.list = []
                this.stopGetAudioLevel()

                // const audioTrack = this.localStream.getAudioTrack();
                // if (audioTrack) {
                //     audioTrack.stop();
                // }

                // const videoTrack = this.localStream.getVideoTrack();
                // if (videoTrack) {
                //     this.localStream.removeTrack(videoTrack)
                //     // 停止采集，关闭摄像头
                //     videoTrack.stop();
                // }
            })
            .catch(error => {
                console.error('退房失败 ' + error);
                // 错误不可恢复，需要刷新页面。
            });
        if (this.shareStream) {
            // 屏幕分享客户端停止推流
            // this.shareClient.unpublish(this.shareStream);
            // 关闭屏幕分享流
            this.shareStream.close();
        }

        if (this.pcflag) {
            this.shareClient.leave()
        }
        this.clientFlag = false
        document.o2.meettingType = false;
        Bus.$emit('localFlag', false)
    }

    // https://api.wh12345.net/trtc/usersig.php?userid=fff&appname=O2VR
    //获取签证
    // get(userid) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .get('https://o2vr.net/usersig/usersig.php', { 
    //                 params: {
    //                     userid,
    //                     sdkappid: '1400610125'
    //                 },

    //             })
    //             .then((res) => {
    //                 resolve(res.data);
    //             })
    //             .catch((err) => {
    //                 reject(err.data);
    //             });
    //     })

    // }

    //O2VR2
    get(userid) {
        return new Promise((resolve, reject) => {
            axios
                .get('https://api.wh12345.net/trtc/usersig.php?', {
                    params: {
                        userid,
                        appname: document.o2.clientType
                    },
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.data);
                });

        })

    }
}

export { o2vrClient };