<template>
  <div id="app" ref="app">
    <router-view />

    <!-- <div class="beian">
      <el-button type="primary" @click="test(true)" v-if="!clientFlag"
        >进入房间</el-button
      >

      <el-button type="warning" @click="test(false)" v-else>退出房间</el-button>
    </div> -->

    <!-- <outLine /> -->
  </div>
</template>

<script>
// import outLine from "./components/outLine.vue";
export default {
  // components: { outLine },
  name: "App",
  data() {
    return {
      clientFlag: false,
      timmer: "",
    };
  },
  methods: {
    test(e) {
      this.clientFlag = e;
      this.Bus.$emit("clientLogin", e);
      if (!e) {
        this.$client.loginOut();
      }
    },
    beforeunloadFn(e) {
      console.log("刷新或关闭");

      this.$chat.ws.close();
      // ...
    },
    screenTest() {
      // var width = document.documentElement.clientWidth;
      // console.log(width);
      // if (width > 1000) {
      //   this.$router.push({ name: "login" });
      // } else if (width < 1000) {
      //   this.$router.push({ name: "mobileLogin" });
      // }
      // else if (width < 500) {&& width > 500
      //   console.log("横屏");
      //   this.$router.push({ name: "mobileTest" });
      // }
    },
  },

  beforeCreate() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    }
  },

  created() {
    this.screenTest();

    // var lastModified = document.lastModified;

    // if (
    //   !localStorage.getItem("lastModified") ||
    //   lastModified != localStorage.getItem("lastModified")
    // ) {
    //   localStorage.setItem("lastModified", document.lastModified);
    //   window.location.reload(true);
    // } else {
    // }
    // localStorage.setItem("token", "a89699ac-2237-4f7a-a201-6849720a2641");
    this.$bridge.registerHandler("h5SendAppToken", (res) => {
      console.log(res);
      res = JSON.parse(res);
      if (res.token) {
        console.log(res.token, "token");
        localStorage.setItem("token", res.token);
      }
    });

    this.$bridge.callHandler("h5GetApptoken", {}, function (str) {
      console.log("mounted");
    });
  },

  mounted() {
    // this.$router.push({ name: "mobileIndex" });
    // this.$bridge.callHandler("h5GetApptoken", {}, function (str) {
    //   console.log("mounted");
    // });
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
html {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "loyFont";
}

#layout-container,
#mainrow,
#main-container {
  margin: auto, 0;
  height: 100%;
  width: 100%;
}
.beian {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  width: 500px;
}
</style>
