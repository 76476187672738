import Vue from 'vue'
import App from './App'
import router from './router'

import 'element-ui/lib/theme-chalk/index.css'
import '../common/css/common.css'
import "../common/css/font.css"

import "./assets/css/index.scss"
import { o2vr } from '../common/js/o2'
import "animate.css/animate.compat.css"

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false
Vue.prototype.O2vr = new o2vr()

document.o2vr = Vue.prototype.O2vr;
document.o2 = Vue.prototype.O2vr;
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);



// document.o2.websocket = 9670
// document.o2.clientType = 'O2VR2'


function getParams(url, params) {
    var res = new RegExp("(?:&|/?)" + params + "=([^&$]+)").exec(url);
    return res ? res[1] : '';
}

var type = getParams(window.location.href, 'meetingType') ? Number(getParams(window.location.href, 'meetingType')) : 0

document.o2.websocket = 9700 + type
document.o2.room_id = 1002 + type

if (type == 0) {
    document.o2.url = 'https://api.wh12345.net/qilu/'
} else {
    document.o2.url = `https://api.wh12345.net/qilu${type}/`
}

document.o2.clientType = 'qilu'



import { o2vrClient } from '../common/js/qiluclient'
import { o2Function } from "../common/js/o2Function"
import { player } from './player'
import { chat } from "../common/js/chatoly"

import bridge from "./assets/js/bridge"
Vue.prototype.$bridge = bridge
Vue.prototype.$chat = new chat()
import Bus from '../common/js/bus'

Vue.prototype.Bus = Bus
//音视频通话
Vue.prototype.$client = new o2vrClient()
Vue.prototype.$o2Function = new o2Function()
Vue.prototype.$player = new player()
document.client = new o2vrClient()
document.chat = new chat()
document.o2.project_id = 4;
document.o2.playerMgr = new playerMgr();
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementUI from 'element-ui';
import { playerMgr } from '../common/js/playerMgr'

Vue.use(ElementUI);
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh',
    messages: {
        'zh': { info: require('./assets/lang/zh.js'), ...zhLocale },
        'en': { info: require('./assets/lang/en.js'), ...enLocale }
    }
});

new Vue({
    router,
    // store,
    i18n,
    render: h => h(App)
}).$mount("#app");
